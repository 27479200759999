import React from "react"
import * as FaIcons from "react-icons/fa"
import * as FiIcons from "react-icons/fi"
import * as AiIcons from "react-icons/ai"
import * as IoIcons from "react-icons/io"
import * as BiIcons from "react-icons/bi"
import * as GrIcons from "react-icons/gr"
import { Links } from "constants/Links"

export const SidebarData = [
  {
    title: "Dashboard",
    path: Links.Dashboard,
    icon: <FiIcons.FiHome size={22} />,
  },
  {
    title: "Team",
    path: Links.Team,
    icon: <FiIcons.FiUsers size={22} />,
  },
  {
    title: "Users",
    path: Links.Users,
    icon: <AiIcons.AiOutlineUser size={22} />,
    detailedNav: [
      {
        title: "User Detail",
        path: Links.UserDetail,
        icon: <IoIcons.IoMdArrowDropright />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Causes",
    path: Links.Causes,
    icon: <FiIcons.FiHeart size={22} />,
  },
  {
    title: "Skills",
    path: Links.Skills,
    icon: <BiIcons.BiTrophy size={22} />,
  },
  {
    title: "Nonprofits",
    path: Links.Nonprofits,
    icon: <BiIcons.BiDonateHeart size={22} />,
    detailedNav: [
      {
        title: "Nonprofit Detail",
        path: Links.NonprofitDetail,
        icon: <IoIcons.IoMdArrowDropright />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Events",
    path: Links.Events,
    icon: <FiIcons.FiCalendar size={22} />,
    detailedNav: [
      {
        title: "Event Detail",
        path: Links.EventDetail,
        icon: <IoIcons.IoMdArrowDropright />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "CRM",
    path: Links.CRM,
    icon: <IoIcons.IoIosInfinite size={22} />,
    detailedNav: [
      {
        title: "CRM Detail",
        path: Links.CRMDetail,
        icon: <IoIcons.IoMdArrowDropright />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Powered by Charity",
    path: Links.PBC,
    icon: <IoIcons.IoIosInfinite size={22} />,
  },
  // {
  //   title: 'Opportunities',
  //   path: Links.Opportunities,
  //   icon: <FaIcons.FaChartLine />
  // },
  // {
  //   title: 'Reports',
  //   icon: <IoIcons.IoIosPaper />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,

  //   subNav: [

  //     {
  //       title: 'Donations',
  //       path: Links.InventoryLocations,
  //       icon: <IoIcons.IoMdArrowDropright />
  //     },
  //   ]
  // },
  // {
  //   title: 'Support',
  //   path: Links.Support,
  //   icon: <IoIcons.IoMdHelpCircle />
  // }
]
