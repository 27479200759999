import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState
} from "react";
import { getOurCauses } from "services/causes-service";
import {
	fetchMoreOrgs,
	fetchOrgs,
	getOrgMembers
} from "services/organizations-service";
import { useAuth } from "./AuthContext";
import { useCauses } from "./CausesContext";

const OrganizationContext = createContext();

export const OrganizationProvider = (props) => {
	const { currentUser } = useAuth();
	const { allCauses } = useCauses();

	const [lastDoc, setLastDoc] = useState(null);
	const [orgs, setOrgs] = useState([]);
	const [getOrgs, setGetOrgs] = useState(false);
	const [page, setPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [orgsCount, setOrgsCount] = useState(0);

	const [selectedOrg, setSelectedOrg] = useState(null);
	const [members, setMembers] = useState([]);
	const [causes, setCauses] = useState([]);
	const [owner, setOwner] = useState(false);
	const [userType, setUserType] = useState("");
	const [currentMember, setCurrentMember] = useState(null);
	const [sponsors, setSponsors] = useState([]);
	const [coverPhoto, setCoverPhoto] = useState(null);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [sorting, setSorting] = useState({
		orderBy: null,
		orderIn: null
	});

	// Get organizations
	useEffect(() => {
		fetchOrgs({
			orgs,
			totalOrgs: orgsCount,
			currentPage,
			nextPage: page,
			type: "nonprofit",
			rowsPerPage,
			sortBy: sorting?.orderBy,
			orderIn: sorting?.orderIn
		})
			.then((doc) => {
				setOrgs(doc.orgs);
				setOrgsCount(doc.totalCount);

				if (currentPage !== page) setCurrentPage(page);
				else {
					setCurrentPage(1);
					setPage(1);
				}
			})
			.catch((err) => console.log({ err }));
	}, [rowsPerPage, sorting, page]);

	// Fetch organization on the basis of pages
	function nextPage() {
		fetchMoreOrgs(lastDoc, setLastDoc, orgs, setOrgs, "nonprofit", rowsPerPage);
		setPage(page + 1);
	}

	useEffect(() => {
		if (!selectedOrg) {
			setMembers([]);
			setOwner(false);
			setUserType("");
			setSponsors([]);
			setCoverPhoto(null);
			return;
		}

		setCoverPhoto(selectedOrg.coverPhoto);

		const memberSub = getOrgMembers(
			currentUser,
			selectedOrg,
			setMembers,
			setSponsors,
			setUserType,
			setCurrentMember,
			() => {}
		);

		return () => {
			memberSub();
		};
	}, [selectedOrg]);

	//set our causes
	useEffect(() => {
		if (!selectedOrg || !allCauses) return;
		getOurCauses(selectedOrg, allCauses, setCauses);
	}, [selectedOrg, allCauses]);

	useEffect(() => {
		if (coverPhoto && selectedOrg) {
			selectedOrg.coverPhoto = coverPhoto;
		}
	}, [coverPhoto]);

	return (
		<OrganizationContext.Provider
			value={{
				orgs,
				setOrgs,
				nextPage,
				lastDoc,
				setLastDoc,
				getOrgs,
				setGetOrgs,
				selectedOrg,
				setSelectedOrg,
				members,
				setMembers,
				currentMember,
				causes,
				setCauses,
				coverPhoto,
				setCoverPhoto,
				page,
				setPage,
				rowsPerPage,
				setRowsPerPage,
				sorting,
				setSorting,
				orgsCount
			}}
		>
			{props.children}
		</OrganizationContext.Provider>
	);
};

export const useOrganization = () => useContext(OrganizationContext);
