import React, { createContext, useContext, useEffect, useState } from "react";
import { First } from "react-bootstrap/esm/PageItem";
import { getEvents } from "services/events-service";
import { firestore, storage } from "utils/firebase";
import { useAuth } from "./AuthContext";

export const EventContext = createContext();
export const useEventContext = () => useContext(EventContext);

export const EventsProvider = ({ children }) => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [opportunities, setOpportunities] = useState([]);

  const { currentAuth } = useAuth();
  useEffect(() => {
    if (!currentAuth) return;
    const unsubscribe = getEvents(setAllEvents);

    return () => unsubscribe();
  }, [currentAuth]);

  useEffect(() => {
    if (!currentAuth) return;
    //filter out events that are not active
    const activeEvents = allEvents.filter((event) => event.startTime.getTime() > new Date().getTime());
    setUpcomingEvents(activeEvents);
  }, [allEvents]);

  return (
    <EventContext.Provider
      value={{
        upcomingEvents, setUpcomingEvents,
        opportunities,
        setOpportunities,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useEvents = () => useContext(EventContext)

// try {

//   const allOrgSnap = await firestore.collection('organizations').get();
//   let tempArray = [];

//   allOrgSnap.forEach(async (orgDoc) => {
//     const eventSnap = await firestore.collection("organizations").doc(orgDoc.id).collection("events").get();
//     eventSnap.forEach((eventDoc) => {
//       tempArray.push({ ...eventDoc.data(), id: eventDoc.id, type: 'events', collectionId: orgDoc.id })
//     });
//     const opportunitiesSnap = await firestore.collection("organizations").doc(orgDoc.id).collection("opportunities").get();
//     opportunitiesSnap.forEach((oppDoc) => {
//       tempArray.push({ ...oppDoc.data(), id: oppDoc.id, type: 'opportunities', collectionId: orgDoc.id })
//     });
//   })
//   setEvents(tempArray)
//   console.log("fsdjklfjsdklfjsdlfjlsd", tempArray)

// } catch (error) {
//   console.log('error in getting events and opportunities', error.message)

// }
