import { auth, firestore, functions } from "utils/firebase";

export const login = async (
	email,
	password,
	setEmailError,
	setPasswordError
) => {
	const checkUser = await functions.httpsCallable("getUserByEmail")({
		email
	});

	const { data } = checkUser;
	const { user } = data;

	if (!user) {
		return setEmailError("No Rayze user corresponding to this email address");
	}

  const rayzeOrg = await firestore
    .collection("organizations")
    .doc("yAy9tjWiEp7IKMRgQJFT")
    .get()

	// const rayzeOrg = await firestore
	// 	.collection("organizations")
	// 	.doc("wc3BWTyYsueKMwzNk5fZ")
	// 	.get();

	if (rayzeOrg.exists) {
		const data = rayzeOrg.data();
		if (data.members.includes(user.uid)) {
			await auth.signInWithEmailAndPassword(email, password).catch((err) => {
				console.log("error signing in", err);
				//return errors

				if (err.code === "auth/invalid-email") {
					setEmailError("Invalid email address");
				} else {
					setPasswordError("Invalid password");
				}
			});
		} else {
			setEmailError("No Rayze user corresponding to this email address");
		}
	}
};

export const logout = () => {
	auth.signOut().then(() => {
		window.location.reload(false);
	});
};

export const sendPasswordReset = (email) => {
	auth
		.sendPasswordResetEmail(email)
		.then(() => {
			window.alert("Password reset email successfully sent");
		})
		.catch((e) => {
			window.alert(e.message);
			// console.log('error sending password reset', e)
		});
};
